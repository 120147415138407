import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, IconButton, Toolbar, Typography, useMediaQuery } from "@mui/material";

import { RxHamburgerMenu } from "react-icons/rx";

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin", "padding"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    margin: "0px",
    width: "calc(100% - 280px)",
    padding: "0 32px",
    transition: theme.transitions.create(["width", "margin", "padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  width: "100%",
  position: "fixed",
  height: "88px",
  zIndex: 999,
}));

const Header = ({ open, handleDrawerToggle, isDemoAccount }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  const mainHeader = (
    <Toolbar sx={{ minHeight: "88px", marginTop: isDemoAccount ? "40px" : 0 }}>
      {matchDownMD && (
        <IconButton
          disableRipple
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          sx={{
            bgcolor: open ? "" : "grey.100",
            ml: { xs: 0, lg: -2 },
            mr: { xs: 1, lg: 0 },
            borderRadius: "8px",
          }}
        >
          <RxHamburgerMenu size={theme.icon.size.lg} color={theme.palette.text.secondary} />
        </IconButton>
      )}
      {/* <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }}>
        <Typography variant="labelLG" color={theme.palette.neutral[500]}>
          Welcome back to{" "}
          <Typography
            variant="labelLG"
            sx={{
              background: "linear-gradient(307deg, #11173B 51.8%, #C0F6F6 87.8%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Auma
          </Typography>{" "}
          Sophia 👋🏻
        </Typography>
      </Box> */}
    </Toolbar>
  );

  const appBar = {
    position: "fixed",
    elevation: 0,
    sx: {
      minHeight: "88px",
      background: theme.palette.common.white,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  };

  return (
    <StyledHeader>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar} style={{ width: "calc(100% - 64px)", margin: "0 32px" }}>
          {mainHeader}
        </AppBar>
      )}
    </StyledHeader>
  );
};

export default Header;
