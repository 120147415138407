import React, { useState } from "react";

import { Box, Dialog, DialogActions, DialogContent, IconButton, MenuItem, Typography, Tooltip, Checkbox, FormControlLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import {
  RiArrowRightSLine,
  RiLogoutBoxLine,
  RiInformationFill,
  RiCoinLine,
  RiUserAddLine,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCloseFill,
} from "react-icons/ri";

import Menu from "../../../components/Menu";
import Button from "../../../components/ui/Button";
import StatusModal from "../../../components/StatusModal";
import MoreCreditDialog from "../../../pages/views/Company/components/more-credit-dialog";
import { CheckedIcon, UncheckedIcon } from "../../../components/icons/Checkbox";

import { dispatch, useSelector } from "../../../store";
import { logoutSuccess } from "../../../store/slices/user";

import { cookieStorage } from "../../../utils/cookie";
import { CoinLine, DefaultProfileIcon } from "../../../utils/images";

import { BorderLinearCreditProgress, DrawerFooterStyled, StyledGradientBadge, StyledTooltipCloseIcon } from "../main-layout-styled";

const DrawerFooter = ({ open }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);

  const [isLogoutDialogOpen, setLogoutDialog] = React.useState(false);
  const [isCreditDialogOpen, setCreditDialogOpen] = React.useState(false);

  const [isChecklistOpen, setChecklistOpen] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggleChecklist = () => {
    setChecklistOpen((prev) => !prev);
  };
  const progressItems = [
    {
      label: "Create your company",
      credits: 10,
      claimed: true,
      buttonLabel: "Start Setup",
    },
    {
      label: "Integrate your calendar",
      credits: 5,
      claimed: false,
      details: "Connect your calendar to easily schedule and manage interviews and meetings.",
      buttonLabel: "Integrate Calendar",
    },
    {
      label: "Invite your team members",
      credits: 5,
      claimed: false,
      details: "Add your team members to collaborate and streamline the hiring process.",
      buttonLabel: "Invite Team",
    },
    {
      label: "Create your first job",
      credits: 5,
      claimed: false,
      details: "Quickly set up your first job posting to start attracting candidates.",
      buttonLabel: "Create Job",
    },
    {
      label: "Book a demo",
      credits: 15,
      claimed: false,
      details: "Schedule a demo to learn how to make the most of the platform.",
      buttonLabel: "Book Demo",
    },
  ];

  const [userDetail, setUserDetail] = React.useState({
    name: "",
    email: "",
    photo: "",
  });

  React.useEffect(() => {
    if (user) {
      setUserDetail({
        name: user.fullName,
        email: user.email,
        photo: user.profileImage,
      });
    }
  }, [user]);

  const logout = () => {
    cookieStorage.removeItem("jwt_uuid");
    cookieStorage.removeItem("uuid");
    cookieStorage.clear();
    dispatch(logoutSuccess());
    navigate("/login");
  };

  const handleProfilePhotoError = (event) => {
    event.target.onerror = null;
    event.target.src = DefaultProfileIcon;
  };

  const handleToggleCreditDialog = () => {
    setCreditDialogOpen((prev) => !prev);
  };

  return (
    <>
      {/* <div className="p-4 pb-0">
        <Button fullWidth Icon={RiUserAddLine} title="Invite your team" variant="neutral" state="Ghost" size="small" className="justify-start" />
      </div>

      <Tooltip
        title={
          <>
            <Box
              sx={{
                padding: "20px 16px 14px",
                backgroundColor: "#FDFDFD !important",
                borderTopLeftRadius: "24px",
                borderTopRightRadius: "24px",
                borderBottom: "1px solid #E2E4E9",
              }}
            >
              <Typography variant="labelXS">Great progress!</Typography>
              <BorderLinearCreditProgress variant="determinate" value={12} className="mt-2" />
              <StyledTooltipCloseIcon onClick={handleToggleChecklist}>
                <RiCloseFill size={16} color={theme.palette.placeHolder} />
              </StyledTooltipCloseIcon>
            </Box>
            <Box sx={{ padding: "7px 10px 12px 10px", background: "white", borderRadius: "0 0 24px 24px" }}>
              {progressItems.map((item, index) => (
                <Box key={index} className="flex flex-col gap-2 p-3">
                  <Box className="flex justify-between">
                    <Box>
                      <FormControlLabel
                        className="m-0"
                        control={<Checkbox className="p-0 mr-2" checked={item.claimed} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />}
                        label={<Typography variant={item.claimed ? "labelSM" : "paragraphSM"}>{item.label}</Typography>}
                      />
                      {expandedIndex !== index && (
                        <>
                          {item.claimed ? (
                            <Box
                              className="inline-flex items-center gap-2 px-1.5 py-0.5 mx-8 mt-1 rounded-full"
                              sx={{
                                border: `1px solid ${item.claimed ? "#C2D6FF" : "#CBF5E5"}`,
                                backgroundColor: item.claimed ? "#EBF1FF" : "#EFFAF6",
                              }}
                            >
                              <RiCoinLine color={item.claimed ? "#303E8E" : "#38C793"} size={16} />{" "}
                              <Typography variant="paragraphXS">
                                {item.claimed ? "" : "Claim"} {item.credits} Credits {item.claimed ? "Claimed" : ""}
                              </Typography>
                            </Box>
                          ) : (
                            <StyledGradientBadge>
                              <RiCoinLine color={item.claimed ? "#303E8E" : "#38C793"} size={16} />{" "}
                              <Typography variant="paragraphXS">
                                {item.claimed ? "" : "Claim"} {item.credits} Credits {item.claimed ? "Claimed" : ""}
                              </Typography>
                            </StyledGradientBadge>
                          )}
                        </>
                      )}
                    </Box>
                    <Box className="cursor-pointer" onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}>
                      {expandedIndex === index ? item.details && <RiArrowUpSLine size={24} /> : item.details && <RiArrowDownSLine size={24} />}
                    </Box>
                  </Box>
                  {expandedIndex === index && (
                    <div>
                      <Typography variant="paragraphXS">{item.details}</Typography>
                      <div className="mt-2 relative z-10">
                        <Button variant="auma" state="Stroke" fullWidth title={item.buttonLabel} size="x-small" />
                      </div>
                    </div>
                  )}
                </Box>
              ))}
            </Box>
          </>
        }
        open={isChecklistOpen}
        placement="right-start"
        onClose={handleToggleChecklist}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        componentsProps={{ tooltip: { className: "onboarding-checklist-tooltip" } }}
      >
        <div className="px-4 pt-3 pb-0" onClick={handleToggleChecklist}>
          <Box
            className="flex justify-between items-center p-2 relative cursor-pointer"
            sx={{
              borderRadius: "12px",
              background: "white",
              boxShadow: "0px 16px 32px -12px rgba(88, 92, 95, 0.10)",
              "&::before": {
                content: "''",
                position: "absolute",
                top: "-1px",
                left: "-1px",
                right: "-1px",
                bottom: "-1px",
                zIndex: "-1",
                borderRadius: "inherit",
                background: "linear-gradient(90deg, #61C0FF 0%, #FF98C9 40%, #63BABA 100%)",
              },
            }}
          >
            <Typography variant="labelSM" color="#525866">
              Onboarding Checklist <span className="font-normal">1/4</span>
            </Typography>
            <RiArrowRightSLine size={24} color="#525866" />
          </Box>
        </div>
      </Tooltip>

      <Menu
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "18px 16px 16px 18px",
            left: "280px !important",
            padding: "0 !important",
            boxShadow: "0px 16px 32px -12px rgba(88, 92, 95, 0.10)",
            width: "325px",
          },
        }}
        trigger={
          <Box
            sx={{
              borderRadius: "16px",
              border: "1px solid #E2E4E9",
              background: "#FFF",
              padding: "14px 10px",
              margin: "16px",
              boxShadow: "0px 16px 32px -12px rgba(88, 92, 95, 0.10)",
              cursor: "pointer",
            }}
          >
            <Box sx={{ display: "flex", gap: "16px", alignItems: "center", zIndex: 9, position: "relative" }}>
              <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <RiCoinLine size={theme.icon.size.lg} color={theme.palette.text.secondary} />
                  <Typography variant="labelSM" color="#525866">
                    8 / 150 Credits
                  </Typography>
                </Box>
                <BorderLinearCreditProgress variant="determinate" value={12} />
              </Box>
              <Button variant="auma" state="Stroke" size="x-small" title="Upgrade" onClick={() => navigate(`/company/${user?._id}/plans`)} />
            </Box>
          </Box>
        }
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
      >
        <Box
          sx={{
            borderBottom: "1px solid #E2E4E9",
            padding: "8px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#F6F8FA",
          }}
        >
          <Typography variant="labelSM" color="#525866">
            Remaining Credits
          </Typography>
          <Button img={CoinLine} variant="neutral" state="Stroke" size="x-small" title="142" sx={{ borderRadius: "10px", padding: "5px 14px" }} />
        </Box>
        <Box sx={{ padding: "18px", display: "flex", alignItems: "center", gap: "12px", width: "100%", zIndex: 9, position: "relative" }}>
          <Button variant="neutral" state="Stroke" size="x-small" title="Buy more credits" fullWidth onClick={handleToggleCreditDialog} />
          <Button
            variant="auma"
            state="Stroke"
            size="x-small"
            title="Manage plan"
            fullWidth
            onClick={() => navigate(`/company/${user?._id}/plans`)}
          />
        </Box>
      </Menu> */}

      <DrawerFooterStyled theme={theme} open={open}>
        <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(3) }}>
          <img src={userDetail.photo} alt="user" onError={handleProfilePhotoError} />
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">{userDetail.name || "N/a"}</Typography>
            <Typography title={userDetail.email || "N/a"} variant="body2">
              {userDetail.email || "N/a"}
            </Typography>
          </Box>
        </Box>
        <Menu
          trigger={
            <IconButton sx={{ borderRadius: "8px" }}>
              <RiArrowRightSLine size={theme.icon.size.lg} color={theme.palette.text.secondary} />
            </IconButton>
          }
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "center", horizontal: "left" }}
        >
          {/* <MenuItem>
            Settings <RiSettings3Line size={theme.icon.size.lg} color={theme.palette.text.secondary} />
          </MenuItem> */}
          <MenuItem onClick={() => setLogoutDialog(true)}>
            Logout <RiLogoutBoxLine size={theme.icon.size.lg} color={theme.palette.text.secondary} />
          </MenuItem>
        </Menu>
      </DrawerFooterStyled>

      <Dialog fullWidth maxWidth="xs" open={isLogoutDialogOpen} className="confirm-dialog">
        <DialogContent>
          <Box className="status-pill">
            <StatusModal variant="Red" icon={<RiInformationFill size={theme.icon.size.lg} />} />
          </Box>
          <Typography variant="body1">Are You Sure You Want to Log Out?</Typography>
          <Typography variant="subtitle1">You are about to log out of your account.</Typography>
        </DialogContent>
        <DialogActions sx={{}}>
          <Button onClick={() => setLogoutDialog(false)} fullWidth title="Cancel" variant="neutral" state="Stroke" />
          <Button onClick={logout} fullWidth title="Log Out" variant="primary" state="Filled" />
        </DialogActions>
      </Dialog>

      <MoreCreditDialog isCreditDialogOpen={isCreditDialogOpen} handleToggleCreditDialog={handleToggleCreditDialog} />
    </>
  );
};

export default DrawerFooter;
