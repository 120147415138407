import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { Box, ListItemButton, ListItemIcon, ListItemText, styled, Typography } from "@mui/material";

import { useDispatch, useSelector } from "../../../../store";
import { activeItem } from "../../../../store/slices/menu";

const StyledTip = styled(Box)(({ theme }) => ({
  width: "4px",
  height: "20px",
  position: "absolute",
  left: "2px",
  top: "-10px",
  borderRadius: theme.spacing(0, 4, 4, 0),
  background: theme.palette.blue.base,
}));

const NavSubItem = ({ item }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { openItem } = useSelector((state) => state.menu);

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />),
  };
  listItemProps.component.displayName = "NavLink";
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
  }, [document.location.pathname]);

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon size={theme.icon.size.lg} color={isSelected ? theme.palette.blue.base : theme.palette.text.secondary} /> : false;

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        p: theme.spacing(2, 3),
        pl: theme.spacing(8),
        gap: theme.spacing(4),
        borderRadius: theme.spacing(2),
        "&:hover": {
          bgcolor: theme.palette.background.paper,
        },
        "&.Mui-selected": {
          bgcolor: "transparent",
          "&:hover": {
            bgcolor: theme.palette.background.paper,
          },
          border: 0,
        },
      }}
    >
      <Box sx={{ position: "relative" }}>{isSelected && <StyledTip />}</Box>
      {itemIcon && <ListItemIcon sx={{ minWidth: "unset" }}>{itemIcon}</ListItemIcon>}
      <ListItemText
        className="m-0"
        primary={
          <Typography variant="labelSM" sx={{ color: isSelected ? theme.palette.text.primary : theme.palette.text.secondary }}>
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

export default NavSubItem;
