import React from "react";
import { Tooltip, Typography, Box, IconButton, Divider } from "@mui/material";
import { RiQuestionLine, RiPhoneLine, RiCoinLine, RiCalendarLine, RiLinkedinBoxFill } from "react-icons/ri";

import { BoostNotificationIcon } from "../../../../utils/images";

const CreditUsageTooltip = ({ children }) => {
  return (
    <Tooltip
      title={
        <div className="py-2 px-3 max-w-[300px]">
          <Typography variant="labelSM" color="#303E8E">
            Credit Usage
          </Typography>
          <div className="pt-4">
            <div className="flex gap-5 items-center pb-3">
              <RiPhoneLine size={20} color="#303E8E" />
              <div className="flex flex-col">
                <Typography variant="labelXS">AI Phone Interview</Typography>
                <Box
                  className="inline-flex items-center mt-1 gap-1 px-1.5 py-0.5 w-fit rounded-3xl"
                  sx={{ border: "1px solid #CBF5E5", backgroundColor: "#EFFAF6" }}
                >
                  <RiCoinLine color="#38C793" size={16} />
                  <Typography variant="paragraphXS">2 Credits</Typography>
                </Box>
              </div>
            </div>

            <Divider />

            <div className="flex gap-5 items-center py-3">
              <RiCalendarLine size={20} color="#303E8E" />
              <div className="flex flex-col">
                <Typography variant="labelXS">Scheduler and Video Interview</Typography>
                <Box
                  className="inline-flex items-center mt-1 gap-1 px-1.5 py-0.5 w-fit rounded-3xl"
                  sx={{ border: "1px solid #CBF5E5", backgroundColor: "#EFFAF6" }}
                >
                  <RiCoinLine color="#38C793" size={16} /> <Typography variant="paragraphXS">2 Credits</Typography>
                </Box>
              </div>
            </div>

            <Divider />

            <div className="flex gap-5 items-center py-3">
              <div className="relative">
                <RiLinkedinBoxFill size={20} color="#0A66C2" />
                <img src={BoostNotificationIcon} alt="" className="absolute top-[-5px] right-[-5px]" />
              </div>
              <div className="flex flex-col">
                <Typography variant="labelXS">Boost Job on LinkedIn - 5 Days</Typography>
                <Box
                  className="inline-flex items-center mt-1 gap-1 px-1.5 py-0.5 w-fit rounded-3xl"
                  sx={{ border: "1px solid #CBF5E5", backgroundColor: "#EFFAF6" }}
                >
                  <RiCoinLine color="#38C793" size={16} /> <Typography variant="paragraphXS">50 Credits</Typography>
                </Box>
              </div>
            </div>

            <Divider />

            <div className="flex gap-5 items-center py-3">
              <div className="relative">
                <RiLinkedinBoxFill size={20} color="#0A66C2" />
                <img src={BoostNotificationIcon} alt="" className="absolute top-[-5px] right-[-5px]" />
              </div>
              <Box>
                <Typography variant="labelXS">Boost Job on LinkedIn - 10 Days</Typography>
                <Box
                  className="inline-flex items-center mt-1 gap-1 px-1.5 py-0.5 w-fit rounded-3xl"
                  sx={{ border: "1px solid #CBF5E5", backgroundColor: "#EFFAF6" }}
                >
                  <RiCoinLine color="#38C793" size={16} /> <Typography variant="paragraphXS">100 Credits</Typography>
                </Box>
              </Box>
            </div>

            <Divider />

            <div className="flex items-center gap-5 pt-3">
              <div className="relative">
                <RiLinkedinBoxFill size={20} color="#0A66C2" />
                <img src={BoostNotificationIcon} alt="" className="absolute top-[-5px] right-[-5px]" />
              </div>
              <Box>
                <Typography variant="labelXS">Boost Job on LinkedIn - 20 Days</Typography>
                <Box
                  className="inline-flex items-center mt-1 gap-1 px-1.5 py-0.5 w-fit rounded-3xl"
                  sx={{ border: "1px solid #CBF5E5", backgroundColor: "#EFFAF6" }}
                >
                  <RiCoinLine color="#38C793" /> <Typography variant="paragraphXS">200 Credits</Typography>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      }
      placement="top-start"
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "white !important",
            color: "#000",
            border: "1px solid #303E8E !important",
            borderRadius: "11px !important",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CreditUsageTooltip;
