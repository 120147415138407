import { RiBriefcaseLine, RiBuilding2Line, RiCalendar2Line, RiDashboardLine } from "react-icons/ri";

const icons = {
  RiBriefcaseLine,
  RiBuilding2Line,
  RiCalendar2Line,
  RiDashboardLine,
};

export const menuItems = (param) => [
  // {
  //   id: "dashboard",
  //   title: "Dashboard",
  //   url: `/dashboard/${param.id}`,
  //   icon: icons.RiDashboardLine,
  //   type: "item",
  // },
  {
    id: "jobs",
    title: "Jobs",
    url: `/jobs/${param.id}`,
    icon: icons.RiBriefcaseLine,
    type: "item",
  },
  {
    id: "company",
    title: "Company",
    icon: icons.RiBuilding2Line,
    type: "group",
    children: [
      {
        id: "company",
        title: "Company Profile",
        type: "item",
        url: `/company/${param.id}`,
      },
      {
        id: "my-team",
        title: "My Team",
        type: "item",
        url: `/company/${param.id}/my-team`,
      },
    ],
  },
  {
    id: "calendar",
    title: "Calendar",
    icon: icons.RiCalendar2Line,
    type: "group",
    children: [
      {
        id: "interviews",
        title: "Interviews",
        type: "item",
        url: `/calendar/${param.id}/interviews`,
      },
      {
        id: "availability",
        title: "Availability",
        type: "item",
        url: `/calendar/${param.id}/availability`,
      },
      {
        id: "integrations",
        title: "Calendar Integrations",
        type: "item",
        url: `/calendar/${param.id}/integrations`,
      },
    ],
  },
];
