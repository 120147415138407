import { dispatch } from "../store";
import { inviteUserSuccess, joinWorkspaceSuccess, loadingSuccess, userSuccess } from "../store/slices/user";
import axiosServices from "../utils/axios";
import { cookieStorage } from "../utils/cookie";

export const getUserService = (id) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.get(`/users/${id}`, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(userSuccess({ user: response.data.data }));
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const updateUserNameService = (id, body) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.patch(`/users/${id}`, body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(userSuccess({ user: response.data.data }));
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const getInviteUserService = (id) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.get(`/company/get-invited-details/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("invite_code")}`,
          "x-custom-access-id": `${localStorage.getItem("invite_id")}`,
        },
      });
      dispatch(inviteUserSuccess({ inviteUser: response.data.data }));
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const joinWorkspaceService = (body) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.patch("/company/handle-invitation", body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(joinWorkspaceSuccess({ joinWorkspace: response.data.data }));
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};
