import { lazy } from "react";
import { Outlet } from "react-router-dom";

import Loadable from "../components/loader/Loadable";

import MainLayout from "../layouts/MainLayout";
import AuthGuard from "../layouts/RouteGuard/AuthGuard";

const DashboardPage = Loadable(lazy(() => import("../pages/views/dashboard/dashboard")));

const CompanyPage = Loadable(lazy(() => import("../pages/views/Company")));
const MyTeamPage = Loadable(lazy(() => import("../pages/views/Company/MyTeam")));
const PricingPage = Loadable(lazy(() => import("../pages/views/Company/Pricing")));

const JobsPage = Loadable(lazy(() => import("../pages/views/Jobs")));
const JobDetailsPage = Loadable(lazy(() => import("../pages/views/Jobs/JobDetails")));
const JobFormPage = Loadable(lazy(() => import("../pages/views/Jobs/JobFom")));
const CreateAIJobPage = Loadable(lazy(() => import("../pages/views/Jobs/create-ai-job")));

const InterviewsPage = Loadable(lazy(() => import("../pages/views/Calendar/Interviews")));
const AvailabilityPage = Loadable(lazy(() => import("../pages/views/Calendar/Availability")));
const IntegrationsPage = Loadable(lazy(() => import("../pages/views/Calendar/Integrations")));

const MainRoutes = {
  children: [
    {
      path: "/dashboard/:id",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <DashboardPage />,
        },
      ],
    },
    {
      path: "/company/:id",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <CompanyPage />,
        },
        {
          path: "create",
          element: <CompanyPage />,
        },
        {
          path: "my-team",
          element: <MyTeamPage />,
        },
      ],
    },
    {
      path: "/company/:id",
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        {
          path: "plans",
          element: <PricingPage />,
        },
      ],
    },
    {
      path: "/jobs/:id",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <JobsPage />,
        },
        {
          path: "job/:jid",
          element: <JobDetailsPage />,
        },
        {
          path: "job/:cid/create",
          element: <JobFormPage />,
        },
        {
          path: "job/:cid/edit/:jid",
          element: <JobFormPage />,
        },
      ],
    },
    {
      path: "/jobs/:id",
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        {
          path: "job/:cid/auma-create",
          element: <CreateAIJobPage />,
        },
      ],
    },
    {
      path: "/calendar/:id",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "interviews",
          element: <InterviewsPage />,
        },
        {
          path: "availability",
          element: <AvailabilityPage />,
        },
        {
          path: "integrations",
          element: <IntegrationsPage />,
        },
      ],
    },
  ],
};

export default MainRoutes;
