export const overflowStyle = {
  "::-webkit-scrollbar": {
    width: "13px",
    height: "13px",
  },
  "::-webkit-scrollbar-track": {
    background: "white",
    borderRadius: "50px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#868C98",
    borderRadius: "50px",
    border: "4px solid #fff",
  },
};

export const overflowBorderStyle = {
  "::-webkit-scrollbar": {
    width: "6px",
    height: "13px",
  },
  "::-webkit-scrollbar-track": {
    background: "white",
    borderRadius: "50px",
    border: "1px solid  #E2E4E9",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#CDD0D5",
    borderRadius: "50px",
  },
};
