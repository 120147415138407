import { Box, LinearProgress, linearProgressClasses, styled, IconButton } from "@mui/material";

export const DrawerFooterStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: any) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "flex-start" : "center",
  padding: theme.spacing(6),
  gap: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,

  "& img": {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },

  "& h6": {
    color: theme.palette.text.primary,
  },

  "& p": {
    width: "135px",
    color: theme.palette.text.secondary,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
  },
}));

export const BorderLinearCreditProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 32,
  border: "1px solid #E2E4E9",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#DBFFBF",
  },
}));

export const StyledTooltipCloseIcon = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  top: "5px",
  right: "10px",
}));

export const StyledGradientBadge = styled("div")(({ theme }) => ({
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px 6px",
  borderRadius: "999px",
  background: "linear-gradient(90deg, #61C0FF 0%, #FF98C9 40%, #63BABA 100%)",
  overflow: "hidden",
  marginTop: "4px",
  gap: "8px",
  marginLeft: "32px",
  marginRight: "32px",

  "&::before": {
    content: "''",
    position: "absolute",
    top: "1px",
    left: "1px",
    right: "1px",
    bottom: "0.5px",
    borderRadius: "inherit",
    backgroundColor: "#CBF5E5",
    zIndex: 1,
  },

  "& > *": {
    position: "relative",
    zIndex: 2,
  },
}));
