import { createSlice } from "@reduxjs/toolkit";

import { IPlanState } from "../../types/plans";

const initialState: IPlanState = {
  loading: true,
  monthlyPlans: [],
  yearlyPlans: [],
};

const plans = createSlice({
  name: "plans",
  initialState,
  reducers: {
    plansListSuccess(state, action) {
      const { plans } = action.payload;
      console.log(plans.data);
      state.loading = false;
    },
    loadingSuccess(state, action) {
      const { loading } = action.payload;
      state.loading = loading;
    },
  },
});

export default plans.reducer;

export const { plansListSuccess, loadingSuccess } = plans.actions;
