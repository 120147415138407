import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import Button from "../../../../components/ui/Button";
import CreditUsageTooltip from "./credit-usage-tooltip";

import { CoinLine } from "../../../../utils/images";

import { RiCoinLine, RiQuestionLine, RiMoneyDollarCircleLine, RiCloseLine, RiArrowLeftSLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const MoreCreditDialog = ({ isCreditDialogOpen, handleToggleCreditDialog }) => {
  const theme = useTheme();
  const [isViewHistoryOpen, setIsViewHistoryOpen] = React.useState(false);

  return (
    <Dialog
      fullWidth
      maxWidth={isViewHistoryOpen ? "md" : "sm"}
      open={isCreditDialogOpen}
      className="more-credit-dialog"
      onClose={handleToggleCreditDialog}
    >
      {!isViewHistoryOpen && (
        <>
          <DialogContent sx={{ padding: "20px 30px !important" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "14px" }}>
                <RiCoinLine size={theme.icon.size.lg} color={theme.palette.text.secondary} />
                <Typography variant="labelLG" color="#525866">
                  Your{" "}
                  <span
                    style={{
                      background: "linear-gradient(90deg, #9CFFF3 0%, #02215B 67.52%)",
                      backgroundClip: "text",
                      webkitBackgroundClip: "text",
                      webkitTextFillColor: "transparent",
                      fontFeatureSettings: "ss11 on, cv09 on, liga off, calt off",
                    }}
                  >
                    Auma Credits
                  </span>
                </Typography>
                <CreditUsageTooltip>
                  <Button Icon={RiQuestionLine} size="x-small" title="How to use" variant="primary" state="Default" />
                </CreditUsageTooltip>
              </Box>
              <RiCloseLine
                className="cursor-pointer"
                onClick={handleToggleCreditDialog}
                size={theme.icon.size.lg}
                color={theme.palette.text.secondary}
              />
            </Box>

            <Box
              sx={{
                marginTop: "40px",
                padding: "10px 36px",
                backgroundColor: "#F6F8FA",
                width: "270px",
                border: "1px solid #E2E4E9",
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                gap: "16px",
                flexDirection: "column",
              }}
            >
              <Typography variant="labelMD" color="#525866">
                REMAINING CREDITS
              </Typography>
              <Button img={CoinLine} variant="neutral" state="Stroke" size="x-small" title="142" sx={{ borderRadius: "10px", padding: "5px 14px" }} />
            </Box>

            <Divider sx={{ margin: "28px 20px" }} />

            <Typography variant="labelMD" color="#525866">
              Top Up Your Credits
            </Typography>
            <Typography variant="paragraphSM" color="#525866" sx={{ width: "492px", marginTop: "12px" }}>
              If you don’t use all your credits, no worries! Your extra credits will{" "}
              <Typography
                variant="paragraphSM"
                sx={{
                  background: "linear-gradient(90deg, #61C0FF, #FF98C9, #63BABA)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  display: "inline",
                }}
              >
                roll over for 2 months
              </Typography>
              , giving you more time to find the missing piece for your team.{" "}
              <Typography variant="paragraphSM" color="#868C98">
                <Link to="#">Learn more here</Link>
              </Typography>
            </Typography>

            <Box sx={{ marginTop: "70px", display: "flex", gap: "16px", alignItems: "center", justifyContent: "center" }}>
              <Button Icon={RiCoinLine} variant="neutral" state="Stroke" size="medium" title="100" sx={{ borderRadius: "10px", width: "110px" }} />
              <Button img={CoinLine} variant="auma" state="Stroke" size="medium" title="142" sx={{ borderRadius: "10px", width: "110px" }} />
              <Button Icon={RiCoinLine} variant="neutral" state="Stroke" size="medium" title="500" sx={{ borderRadius: "10px", width: "110px" }} />
              <Button Icon={RiCoinLine} variant="neutral" state="Stroke" size="medium" title="1000" sx={{ borderRadius: "10px", width: "110px" }} />
            </Box>

            <Divider sx={{ margin: "28px 20px" }} />

            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", marginBottom: "20px" }}>
              <Typography variant="paragraphSM" color="#525866">
                You will pay
              </Typography>
              <Button
                Icon={RiMoneyDollarCircleLine}
                variant="neutral"
                state="Stroke"
                size="x-small"
                title="200"
                sx={{ borderRadius: "10px", padding: "3px 28px" }}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ backgroundColor: "#F6F8FA", display: "flex", justifyContent: "flex-end !important", zIndex: "9" }}>
            <Button onClick={() => setIsViewHistoryOpen(true)} title="View History" variant="primary" state="Ghost" />
            <Button title="Buy 200 credits" variant="primary" state="Filled" sx={{ padding: "10px 22px" }} />
          </DialogActions>
        </>
      )}
      {isViewHistoryOpen && (
        <DialogContent sx={{ padding: "0 !important" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "24px 26px", backgroundColor: "#F6F8FA" }}>
            <Button
              Icon={RiArrowLeftSLine}
              onClick={() => setIsViewHistoryOpen(false)}
              title="Go Back"
              size="medium"
              variant="neutral"
              state="Stroke"
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px", flexDirection: "column" }}>
              <Typography variant="labelLG" color="#253EA7">
                8 credit spent
              </Typography>
              <Typography variant="paragraphSM" color="#525866">
                From December 27, 2024 to December 30, 2024
              </Typography>
            </Box>
            <RiCloseLine onClick={handleToggleCreditDialog} size={theme.icon.size.lg} color={theme.palette.text.secondary} />
          </Box>
          <Box sx={{ padding: "20px 26px" }}>
            <TableContainer>
              <Table sx={{ minWidth: 500 }}>
                <TableHead sx={{ background: "#F6F8FA" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "8px 12px",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        color: "#525866",
                        borderRadius: "8px 0 0 8px",
                      }}
                    >
                      User
                    </TableCell>
                    <TableCell sx={{ padding: "8px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "20px", color: "#525866" }}>
                      Date
                    </TableCell>
                    <TableCell sx={{ padding: "8px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "20px", color: "#525866" }}>
                      Feature
                    </TableCell>
                    <TableCell sx={{ padding: "8px 12px", fontSize: "14px", fontWeight: "400", lineHeight: "20px", color: "#525866" }}>
                      Credits
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRadius: "0 8px 8px 0",
                        padding: "8px 12px",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        color: "#525866",
                      }}
                      align="right"
                    >
                      Invoice
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default MoreCreditDialog;
