import { useEffect } from "react";
import { CalProvider } from "@calcom/atoms";
import { useIntercom } from "react-use-intercom";

import ThemeCustomization from "./themes";
import Routes from "./routes";

import { JWTProvider } from "./contexts/JWTContext";

import ScrollTop from "./utils/ScrollTop";
import { API_URL, CAL_API_URL, CAL_OAUTH_CLIENT_ID, GOOGLE_PLACES_API_KEY, nodeEnv } from "./utils/environment";

import { dispatch, RootState, useSelector } from "./store";
import { getCalUserService } from "./services/calcom";

import { initGA, logPageView } from "./helper/analytics";
import { amplitudeInstance } from "./helper/amplitude";

function loadHotjar(hjid: number, hjsv: number): void {
  (function (h: any, o: Document, t: string, j: string, a?: HTMLHeadElement, r?: HTMLScriptElement) {
    h.hj =
      h.hj ||
      function (...args: any[]) {
        (h.hj!.q = h.hj!.q || []).push(args);
      };
    h._hjSettings = { hjid, hjsv };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = true;
    r.src = t + hjid + j + hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
}

if (nodeEnv === "development") {
  loadHotjar(5210847, 6);
} else if (nodeEnv === "production") {
  require("./assets/styles/calcom.css");
  loadHotjar(5243934, 6);
}

import "./assets/styles/calender.css";

const App = () => {
  const { boot, shutdown } = useIntercom();

  const { user } = useSelector((state: RootState) => state.user);
  const { calUser } = useSelector((state: any) => state.calCom);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    if (nodeEnv === "production") {
      initGA();
      logPageView();
      amplitudeInstance.init("3e290387ea95d18ae02ca86fe97b6255");
    }

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (user) {
      // @ts-ignore
      boot({ user_id: user._id, name: user.fullName, email: user.email, created_at: user.createdAt });
      if (user) {
        dispatch(getCalUserService(user._id));
      }
    } else {
      boot();
    }
    return () => shutdown();
  }, [user]);

  return (
    <CalProvider
      accessToken={calUser?.access_token}
      clientId={CAL_OAUTH_CLIENT_ID ?? ""}
      options={{ apiUrl: CAL_API_URL ?? "", refreshUrl: `${API_URL}/api/v1/cal/refreshtoken/${user?._id}` }}
    >
      <ThemeCustomization>
        <ScrollTop>
          <JWTProvider>
            <Routes />
          </JWTProvider>
        </ScrollTop>
      </ThemeCustomization>
    </CalProvider>
  );
};

export default App;
