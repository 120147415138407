import React, { useEffect, useState } from "react";
import { Box, Collapse, ListItemButton, ListItemIcon, ListItemText, styled, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";

import NavSubItem from "./NavSubItem";

import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";

import { useSelector } from "../../../../store";

const StyledTip = styled(Box)(({ theme }) => ({
  width: "4px",
  height: "20px",
  position: "absolute",
  left: "-20px",
  top: "12px",
  borderRadius: theme.spacing(0, 4, 4, 0),
  background: theme.palette.blue.base,
}));

const StyledDiv = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 32,
  top: 10,
  bottom: 10,
  width: "2px",
  backgroundColor: theme.palette.divider,
  zIndex: 9999,
}));

const NavGroup = ({ menu, level, parentId }) => {
  const theme = useTheme();

  const { drawerOpen } = useSelector((state) => state.menu);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case "item":
        return <NavSubItem key={item.id} item={item} level={level + 1} parentId={parentId} />;
    }
  });

  const openMini = Boolean(anchorEl);
  const { pathname } = useLocation();

  const isSelected = selected === menu.id;

  const Icon = menu.icon;
  const itemIcon = menu.icon ? <Icon size={theme.icon.size.lg} color={isSelected ? theme.palette.blue.base : theme.palette.text.secondary} /> : false;

  const collapseIcon = drawerOpen ? <RiArrowUpSLine size="20px" /> : <RiArrowDownSLine size="20px" />;

  const handleClick = (event) => {
    setAnchorEl(null);
    if (drawerOpen) {
      setOpen(!open);
    } else {
      setAnchorEl(event?.currentTarget);
    }
  };

  const checkOpenForParent = (child, id) => {
    child.forEach((item) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  useEffect(() => {
    setOpen(false);
    setSelected(null);
    if (openMini) setAnchorEl(null);
    if (menu.children) {
      menu.children.forEach((item) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id);
        }
        if (pathname && pathname.includes(menu.id)) {
          if (item.url && item.url.includes(menu.id)) {
            setSelected(menu.id);
            setOpen(true);
          }
        }
        if (item.url === pathname) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }
  }, [pathname, menu.children]);

  return (
    <>
      <ListItemButton
        disabled={menu.disabled}
        selected={isSelected}
        sx={{
          zIndex: 1201,
          p: theme.spacing(2, 3),
          m: "2px 0",
          gap: theme.spacing(2),
          borderRadius: theme.spacing(2),
          "&:hover": {
            bgcolor: theme.palette.background.paper,
          },
          "&.Mui-selected": {
            bgcolor: theme.palette.background.paper,
            "&:hover": {
              bgcolor: theme.palette.background.paper,
            },
            border: 0,
          },
        }}
        onClick={handleClick}
      >
        {itemIcon && <ListItemIcon sx={{ minWidth: "unset" }}>{itemIcon}</ListItemIcon>}
        <ListItemText
          className="m-0"
          primary={
            <Typography variant="labelSM" sx={{ color: isSelected ? theme.palette.text.primary : theme.palette.text.secondary }}>
              {menu.title}
            </Typography>
          }
        />
        {isSelected && <StyledTip />}
        {openMini || open ? collapseIcon : <RiArrowDownSLine size="20px" />}
      </ListItemButton>
      {drawerOpen && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{
            "& .MuiCollapse-wrapperInner": {
              display: "grid",
              gap: theme.spacing(1),
              gridAutoFlow: "row",
              gridAutoRows: "max-content",
              position: "relative",
            },
          }}
        >
          <StyledDiv />
          {open && menus}
        </Collapse>
      )}
    </>
  );
};

export default NavGroup;
