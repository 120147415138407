import React from "react";

import { Button, styled, useTheme } from "@mui/material";

const getVariantStyles = (variant, state, theme) => {
  switch (variant) {
    case "primary":
      switch (state) {
        case "Filled":
          return {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.blue.base,
            boxShadow: "0px 1px 2px 0px rgba(55, 93, 251, 0.08)",
            transition: "border-color 0.3s, background-color 0.3s, background 0.3s, box-shadow 0.3s",

            "&:hover": {
              backgroundColor: theme.palette.blue.dark,
            },

            "&:focus-within": {
              background: theme.palette.blue.base,
            },

            "&:focus-within::before": {
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #EBF1FF",
              background: "linear-gradient(90deg, #61C0FF 0%, #FF98C9 40%, #63BABA 100%)",
            },

            "&::before": {
              content: "''",
              position: "absolute",
              top: "-1px",
              left: "-1px",
              right: "-1px",
              bottom: "-1px",
              zIndex: "-1",
              borderRadius: "inherit",
              background: "linear-gradient(90deg, #61C0FF 0%, #FF98C9 40%, #63BABA 100%)",
            },
            "&.Mui-disabled::before": {
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
            },
          };
        case "Stroke":
          return {
            color: theme.palette.blue.base,
            border: `1px solid ${theme.palette.blue.base}`,
            backgroundColor: theme.palette.common.white,
            boxShadow: "0px 1px 2px 0px rgba(55, 93, 251, 0.08)",
            transition: "border-color 0.3s, background-color 0.3s, box-shadow 0.3s",

            "&:hover": {
              backgroundColor: theme.palette.blue.lighter,
              border: `1px solid ${theme.palette.blue.lighter}`,
            },

            "&:focus-within": {
              border: `1px solid ${theme.palette.blue.base}`,
              backgroundColor: theme.palette.common.white,
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #EBF1FF",
            },
          };
        case "Ghost":
          return {
            color: theme.palette.blue.base,
            border: "1px solid transparent",
            backgroundColor: "transparent",
            transition: "border-color 0.3s, background-color 0.3s, box-shadow 0.3s",

            "&:hover": {
              backgroundColor: theme.palette.blue.lighter,
              border: `1px solid ${theme.palette.blue.lighter}`,
            },

            "&:focus-within": {
              border: `1px solid ${theme.palette.blue.base}`,
              backgroundColor: theme.palette.common.white,
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #EBF1FF",
            },
          };
        case "Blue":
          return {
            color: theme.palette.common.white,
            boxShadow: "0px 1px 2px 0px rgba(37, 62, 167, 0.48), 0px 0px 0px 1px #375DFB",
            border: "1px solid #FFFFFF",
            background: "linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), #303E8E",
            transition: "border-color 0.5s, background-color 0.5s, box-shadow 0.5s",

            "&:hover": {
              background: "linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%), #303E8E",
            },

            "&:focus-within": {
              background: "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.16) 100%), #303E8E",
            },
          };
        case "Default":
          return {
            color: theme.palette.blue.base,
            border: `1px solid ${theme.palette.blue.lighter}`,
            background: theme.palette.blue.lighter,
            transition: "border-color 0.5s, background-color 0.5s, box-shadow 0.5s",

            "&:hover": {
              border: `1px solid ${theme.palette.blue.base}`,
              background: theme.palette.common.white,
              boxShadow: "0px 1px 2px 0px rgba(55, 93, 251, 0.08)",
            },

            "&:focus-within": {
              border: `1px solid ${theme.palette.blue.base}`,
              background: theme.palette.common.white,
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #EBF1FF",
            },
          };
        default:
          return {};
      }
    case "neutral":
      switch (state) {
        case "Filled":
          return {
            color: theme.palette.common.white,
            border: `1px solid ${theme.palette.surface[700]}`,
            backgroundColor: theme.palette.surface[700],
            boxShadow: "0px 1px 2px 0px rgba(82, 88, 102, 0.06)",
            transition: "border-color 0.3s, background-color 0.3s, box-shadow 0.3s",

            "&:hover": {
              backgroundColor: theme.palette.text.primary,
              border: `1px solid ${theme.palette.text.primary}`,
            },

            "&:focus-within": {
              border: `1px solid ${theme.palette.surface[700]}`,
              backgroundColor: theme.palette.surface[700],
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #E4E5E7",
            },

            "&::before": {
              content: "''",
              position: "absolute",
              top: "-1px",
              left: "-1px",
              right: "-1px",
              bottom: "-1px",
              zIndex: "-1",
              borderRadius: "inherit",
              background: "linear-gradient(90deg, #61C0FF 0%, #FF98C9 40%, #63BABA 100%)",
            },

            "&.Mui-disabled::before": {
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
            },
          };
        case "Stroke":
          return {
            color: theme.palette.text.secondary,
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.common.white,
            boxShadow: "0px 1px 2px 0px rgba(82, 88, 102, 0.06)",
            transition: "border-color 0.3s, background-color 0.3s, box-shadow 0.3s",

            "&:hover": {
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.background.paper}`,
              color: theme.palette.text.primary,
            },

            "&:focus-within": {
              border: `1px solid ${theme.palette.text.primary}`,
              backgroundColor: theme.palette.common.white,
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #E4E5E7",
            },
          };
        case "Ghost":
          return {
            color: theme.palette.font.main,
            border: `1px solid ${theme.palette.common.white}`,
            backgroundColor: theme.palette.common.white,
            transition: "border-color 0.3s, background-color 0.3s, box-shadow 0.3s",

            "&:hover": {
              backgroundColor: theme.palette.back.weak,
              border: `1px solid ${theme.palette.back.weak}`,
            },

            "&:focus-within": {
              border: `1px solid ${theme.palette.stroke.strong}`,
              backgroundColor: theme.palette.common.white,
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #E4E5E7",
            },
          };
        case "Linear":
          return {
            color: "#0A0D14",
            backgroundColor: theme.palette.common.white,
            transition: "border-color 0.3s, background-color 0.3s, box-shadow 0.3s",
            boxShadow: "0px 1px 2px 0px rgba(55, 93, 251, 0.08)",

            "&::before": {
              content: "''",
              position: "absolute",
              top: "-1.2px",
              left: "-1.2px",
              right: "-1.2px",
              bottom: "-1.2px",
              zIndex: "-1",
              borderRadius: "inherit",
              background: "linear-gradient(90deg, #61C0FF 0%, #FF98C9 60%, #63BABA 100%)",
            },

            "&:hover": {
              backgroundColor: theme.palette.common.white,
            },
          };
        default:
          return {};
      }
    case "error":
      switch (state) {
        case "Filled":
          return {
            color: theme.palette.common.white,
            border: `1px solid ${theme.palette.red.base}`,
            backgroundColor: theme.palette.red.base,
            boxShadow: theme.shadows.error,
            transition: "border-color 0.3s, background-color 0.3s, box-shadow 0.3s",

            "&:hover": {
              backgroundColor: theme.palette.red.dark,
            },

            "&:focus-within": {
              background: theme.palette.red.base,
              boxShadow: theme.shadows.focusError,
            },
          };
        case "Stroke":
          return {
            color: theme.palette.red.base,
            border: `1px solid ${theme.palette.red.base}`,
            backgroundColor: "transparent",
            boxShadow: theme.shadows.error,
            transition: "border-color 0.3s, background-color 0.3s, box-shadow 0.3s",

            "&:hover": {
              backgroundColor: theme.palette.red.lighter,
              border: `1px solid ${theme.palette.background.paper}`,
            },

            "&:focus-within": {
              border: `1px solid ${theme.palette.red.base}`,
              backgroundColor: theme.palette.common.white,
              boxShadow: theme.shadows.focusError,
            },
          };
        default:
          return {};
      }
    case "success":
      switch (state) {
        case "Filled":
          return {
            color: theme.palette.common.white,
            border: `1px solid ${theme.palette.success.main}`,
            backgroundColor: theme.palette.success.main,
            boxShadow: theme.shadows.success,
            transition: "border-color 0.3s, background-color 0.3s, box-shadow 0.3s",

            "&:hover": {
              backgroundColor: theme.palette.green.dark,
            },

            "&:focus-within": {
              background: theme.palette.success.main,
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #E4E5E7",
            },
          };
        default:
          return {};
      }
    case "auma":
      switch (state) {
        case "Filled":
          return {};
        case "Stroke":
          return {
            color: theme.palette.blue.base,
            backgroundColor: theme.palette.common.white,
            boxShadow: "0px 1px 2px 0px rgba(55, 93, 251, 0.08)",
            transition: "border-color 0.3s, background-color 0.3s, background 0.3s, box-shadow 0.3s",

            "&:hover": {
              backgroundColor: theme.palette.blue.lighter,
            },

            "&:focus-within": {
              background: theme.palette.common.white,
            },

            "&:focus-within::before": {
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #EBF1FF",
              background: "linear-gradient(90deg, #61C0FF 0%, #FF98C9 40%, #63BABA 100%)",
            },

            "&::before": {
              content: "''",
              position: "absolute",
              top: "-1px",
              left: "-1px",
              right: "-1px",
              bottom: "-1px",
              zIndex: "-1",
              borderRadius: "inherit",
              background: "linear-gradient(90deg, #61C0FF 0%, #FF98C9 40%, #63BABA 100%)",
            },

            "&.Mui-disabled::before": {
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
            },
          };
        case "Ghost":
          return {};
        case "Blue":
          return {};
        case "Default":
          return {};
        default:
          return {};
      }
    default:
      return {};
  }
};

const getSizeVariantStyles = (size, theme) => {
  switch (size) {
    case "medium":
      return {
        padding: theme.spacing(2.5),
        borderRadius: theme.border.md,
      };
    case "small":
      return {
        padding: theme.spacing(2),
        borderRadius: theme.border.sm,
      };
    case "x-small":
      return {
        padding: theme.spacing(1.5),
        borderRadius: theme.border.xs,
      };
  }
};

const StyledButton = styled(Button)(({ theme, variant, state, size }) => ({
  display: "flex",

  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  textAlign: "center",
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: "20px",
  letterSpacing: "-0.084px",
  minWidth: "unset",
  ...getVariantStyles(variant, state, theme),
  ...getSizeVariantStyles(size, theme),

  "&.Mui-disabled": {
    background: theme.palette.background.paper,
    color: theme.palette.text.disabled,
    border: `1px solid ${theme.palette.background.paper}`,
  },

  "& img": {
    width: "20px",
    height: "20px",
    borderRadius: "4px",
  },
}));

const CButton = ({
  className,
  img,
  Icon,
  RightIcon,
  title,
  fullWidth = false,
  variant = "primary",
  state = "Filled",
  size = "medium",
  disabled = false,
  ...props
}) => {
  const theme = useTheme();

  return (
    <StyledButton
      disableElevation
      size={size}
      fullWidth={fullWidth}
      variant={variant}
      state={state}
      disabled={disabled}
      className={className}
      {...props}
    >
      {img && <img src={img} alt={title} />}
      {Icon && <Icon size={theme.icon.size.lg} />}
      {title}
      {RightIcon && <RightIcon size={theme.icon.size.lg} />}
    </StyledButton>
  );
};

export default CButton;
