import { combineReducers } from "redux";

import menuReducer from "./slices/menu";
import snackbarReducer from "./slices/snackbar";
import userReducer from "./slices/user";
import companyReducer from "./slices/company";
import jobReducer from "./slices/jobs";
import calComReducer from "./slices/calcom";
import plansReducer from "./slices/plans.slice";

const reducers = combineReducers({
  menu: menuReducer,
  snackbar: snackbarReducer,
  user: userReducer,
  company: companyReducer,
  jobs: jobReducer,
  calCom: calComReducer,
  plans: plansReducer,
});

export default reducers;
